
.number-button {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 2px;
    position: relative; 
    /* background-color: rgb(199, 199, 199); */
    background-color:#f5F5F5;
   
  }
  
  .number-button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .alphabet {
    font-size: 12px;
    margin-top: 5px;
  }
  .fa-phone-volume{
    font-size:1.8rem;
    padding-top:5px;
    padding-right:5px;
  }
  .phone{
    width:70px;
    height:70px;
  
  }
  .form-control {
    background: none;
    outline: none;
    box-shadow: none;
    width: calc(100% - 40px); 
  }

  .backspace{
    top:0%;
    right:-1%;
    cursor: pointer;
  }
  
  .btn-backspace:hover {
    color: #fff;
  }
 